import React, { useEffect, useState } from 'react'
import { FiCheck, FiMapPin, FiRefreshCw } from 'react-icons/fi'
import GoogleMapReact from 'google-map-react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import Button from './UI/Button'
import Card from './UI/Card'
import Info from './UI/Info'
import Label from './UI/Label'
import Popup from './UI/Popup'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { findPickup } from '../api'
import { useKey } from 'react-use'
import FranceWarning from './FranceWarning'
import BrandIcon from './UI/BrandIcon'

function parseCoord(string) { return parseFloat(string.replace(',', '.')) }
function formatTime(string) { 
  const date = new Date(0)
  date.setHours(parseInt(string.substr(0, 2)))
  date.setMinutes(parseInt(string.substr(2, 2)))
  return date.toISOString().substr(11, 5).replace(':', 'h').replace('00', '')
}

function AdressModal({ event, confirmAddress, close }) {
  const [address, setAddress] = useState(event.address || { first_name: event.firstname, last_name: event.lastname, email: event.email, phone: event.phone, address1: '', address2: '', zip: '', city: '', country: 'Belgique'  })

  const selectAdress = (autoAddress, id) => {
    geocodeByPlaceId(id).then(result => {
      const getComponent = type =>
        result[0].address_components.find(c => c.types.find(t => t === type))?.long_name
      setAddress({
        ...address,
        autoAddress,
        address1: `${getComponent('street_number') || ''} ${getComponent('route')}`,
        zip: getComponent('postal_code'),
        city: getComponent('locality'),
        country: getComponent('country')
      })
    })
    .catch(error => console.error(error))
  }

  return (
    <Popup title="Mon adresse" close={close}>
      <div className="flex flex-wrap justify-center items-center">
        <input name="address" style={{ opacity: 0, position: 'absolute' }} />
        <input name="adresse" style={{ opacity: 0, position: 'absolute' }} />
        <input name="name" style={{ opacity: 0, position: 'absolute' }} />
        <Label title="Prénom">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, first_name: e.target.value })}
            type="text"
            value={address.first_name}
          />
        </Label>
        <Label title="Nom">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, last_name: e.target.value })}
            type="text"
            value={address.last_name}
          />
        </Label>
        <Label title="Email">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, email: e.target.value })}
            type="text"
            value={address.email}
          />
        </Label>
        <Label title="Téléphone">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, phone: e.target.value })}
            type="text"
            value={address.phone}
          />
        </Label>
        <Label title="Adresse d'expédition">
          <PlacesAutocomplete
            value={address.address1}
            onChange={a => setAddress({ ...address, address1: a })}
            onSelect={selectAdress}
            searchOptions={{ types: ['address'], componentRestrictions: { country: ['fr', 'be', 'ch', 'lu'] } }}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="relative w-full">
                <input
                  className="form-input w-full"
                  {...getInputProps()}
                  autoComplete="nope" />
                {suggestions.length > 0 &&
                  <div className="absolute bg-white shadow-lg text-base z-10 text-left w-full border border-gray-200">
                    {suggestions.map(s => 
                      <div className="px-2 py-2 hover:bg-blue hover:text-white cursor-pointer leading-none border-b border-gray-100" {...getSuggestionItemProps(s)}>
                        {s.formattedSuggestion.mainText}
                        <br/>
                        <small>{s.formattedSuggestion.secondaryText}</small>
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
          </PlacesAutocomplete>
        </Label>
        <Label title="Complément d'adresse">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, address2: e.target.value })}
            type="text"
            value={address.address2}
          />
        </Label>
        <Label title="Code postal" className="w-1/3">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, zip: e.target.value })}
            type="text"
            value={address.zip}
          />
        </Label>
        <Label title="Ville" className="w-1/3">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, city: e.target.value })}
            type="text"
            value={address.city}
          />
        </Label>
        <Label title="Pays" className="w-1/3">
          <select className="form-input w-full" value={address.country} onChange={e => setAddress({ ...address, country: e.target.value })}>
            <option value="France">France</option>
            <option value="Belgique">Belgique</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Suisse">Suisse</option>
          </select>
        </Label>
        {(address.country === 'France' && !event.dealer) && <FranceWarning />}
        <Button className="mt-4" onClick={() => confirmAddress(address).then(close)}>Valider</Button>
      </div>
    </Popup>
  )
}

function PickupModal({ event, setPickup, close }) {
  const [search, setSearch] = useState([])
  const [selected, setSelected] = useState(event.pickup)
  const [map, setMap] = useState()
  const [country, setCountry] = useState(selected ? selected.Pays : 'BE')
  const [zip, setZip] = useState(selected ? selected.CP : '')
  const triggerSearch = async (reset = false) => {
    setSearch(await findPickup(country, zip))
    if (reset) setSelected(null)
  }
  useKey('Enter', () => triggerSearch(true), {}, [zip, country])
  useEffect(() => { selected && triggerSearch() }, [])
  const center = selected || search[0] || { Latitude: '48.9148531', Longitude: '2.3595186' }

  return (
    <Popup title="Choix du point relais" close={close}>
      <div className="flex justify-center items-end -mx-2 lg:-mx-5">
        <Label title="Pays" className="text-base w-1/3">
          <select className="form-input w-full" value={country} onChange={e => setCountry(e.target.value)}>
            <option value="FR">France</option>
            <option value="BE">Belgique</option>
            <option value="LU">Luxembourg</option>
          </select>
        </Label>
        <Label title="Code postal" className="text-base w-1/3">
          <input type="text" className="form-input w-full" value={zip} onChange={e => setZip(e.target.value)} />
        </Label>
        <Button className=" mx-1 lg:mx-3 mb-4 lg:mb-6" variant="secondary" onClick={() => triggerSearch(true)}>Rechercher</Button>
      </div>
      <div className="max-w-screen-sm w-full h-84 rounded overflow-hidden">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q' }}
          defaultCenter={[48.9148531, 2.3595186]}
          center={search[0] && [parseCoord(center.Latitude), parseCoord(center.Longitude)]}
          defaultZoom={15}
          onZoomAnimationStart={() => setSelected(selected ? { ...selected } : null)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => setMap(map)}>
          {search.map(point => 
            <FaMapMarkerAlt
              className={`w-8 h-8 -ml-4 -mt-6 flex justify-center items-center text-base cursor-pointer ${(selected && selected.Num === point.Num) ? 'text-orange' : 'text-blue-dark'}`}
              lat={parseCoord(point.Latitude)}
              lng={parseCoord(point.Longitude)}
              onClick={() => setSelected(point)} />
          )}
          {map && map.zoom > 13 && search.map(point => 
            <div
              className={`w-32 h-8 -ml-16 mt-3 font-bold text-center text-xs cursor-pointer ${(selected && selected.Num === point.Num) ? 'text-orange' : 'text-blue-dark'}`}
              style={{ textShadow: '2px 2px 0 white' }}
              lat={parseCoord(point.Latitude)}
              lng={parseCoord(point.Longitude)}
              onClick={() => setSelected(point)}>
              {point.LgAdr1}
            </div>
          )}
        </GoogleMapReact>
      </div>
      {selected &&
        <div className="flex justify-start w-full items-center text-left mt-8 mb-4 text-gray-700">
          <div className="h-32 w-32 bg-cover bg-center bg-gray-100 rounded mr-8" style={{ backgroundImage: `url(${selected.URL_Photo})` }} />
          <div className="w-48 leading-tight mr-4">
            <div>{selected.LgAdr1}</div>
            <div className="text-sm">{selected.LgAdr3.toLowerCase()}</div>
            <div className="text-sm">{selected.CP} {selected.Ville}</div>
          </div>
          <div className="leading-none hidden lg:block">
            {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
            .filter(d => selected[`Horaires_${d}`].string.filter(h => !['0000', ''].includes(h)).length > 0).map(d =>
              <div className="flex justify-between items-center text-xs mb-1">
                <div className="w-16">{d}</div>
                <div className="font-thin text-right">
                  {selected[`Horaires_${d}`].string.filter(h => !['0000', ''].includes(h)).map((h, index) => 
                      <>
                        &nbsp;
                        {formatTime(h)}
                        {!(index % 2) && ' →'} 
                      </>
                    )
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      }
      {country === 'FR' && <FranceWarning />}
      <Button className="mt-2 lg:mt-4" onClick={() => { setPickup(selected); close() }}>Valider</Button>
    </Popup>
  )
}

export function DefaultProShipping({ dealer, shippings, setPickup, setAddress, setType }) {
  const [open, setOpen] = useState(false)
  const selected = dealer.defaultProducts.filter(p => p.shipping).sort((a, b) => b.stripeCache.unit_amount - a.stripeCache.unit_amount)[0]
  const premium = true || selected?.upsale
  return (
    <div className="flex flex-col justify-center bg-white rounded shadow-md text-gray-600 py-4 px-6 m-2 lg:m-5 w-full lg:w-1/3+m">
      <div><GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q&libraries=places' }} /></div>
      <div className="flex text-2xl lg:text-3xl mb-2">
        <div className="relative h-10 w-10 mr-4 flex-shrink-0"><BrandIcon Icon={FiMapPin} /></div>
        Livraison
      </div>
      <div className="">
        <div className="text-sm my-3"><LogisticCondensed event={dealer} /></div>
        <div className="flex">
          <Button variant="secondary" className="mt-4 mr-2" onClick={() => setOpen(true)}>Modifier</Button>
          <Button Icon={FiRefreshCw} variant="secondary" className="mt-4" onClick={() => setType(shippings.find(s => s.id != selected.id))}>{premium ? 'En point relais' : 'À domicile'}</Button>
        </div>
      </div>
      {open && premium &&
        <AdressModal event={dealer} confirmAddress={setAddress} close={() => setOpen(false)} />
      }
      {open && !premium &&
        <PickupModal event={dealer} setPickup={setPickup} close={() => setOpen(false)} />
      }
    </div>
  )
}

export function LogisticCondensed({ event }) {
  if (!event.stripeCache) return null
  const products = event.defaultProducts || event.products || []

  const selected = products.filter(p => p.shipping).sort((a, b) => b.stripeCache.unit_amount - a.stripeCache.unit_amount)[0]
  const pickup = event.pickup ? `${event.pickup.LgAdr1} ${event.pickup.Ville}` : 'non défini'
  const address = event.address ? `${event.address.first_name} ${event.address.phone}, ${event.address.address1} ${event.address.city}` : 'non défini'
  return (true || selected?.upsale)
    ? <><strong className="font-bold">Livraison</strong> {address}</>
    : <><strong className="font-bold">Point relais</strong> {pickup}</>
}

export default function Logistic({ event, shippings, setPickup, setAddress }) {
  const [open, setOpen] = useState(false)
  const invoices = event.stripeCache ? [...event.stripeCache.invoices, event.stripeCache.upcoming] : []
  const selected = shippings.filter(s =>
    invoices.find(i => i && i.lines.data.find(l => l.price.product === s.stripeCache.product.id))
  ).sort((a, b) => b.stripeCache.unit_amount - a.stripeCache.unit_amount)[0]
  const premium = true || selected?.upsale
  return (
    <>
      <div><GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q&libraries=places' }} /></div>
      <Card
        className={`w-1/3+sm lg:w-1/3+m ${(event.pickup || event.address) ? 'border-2 border-orange' : ''}`}
        headerClassName={(event.pickup || event.address) ? 'bg-orange text-white' : 'text-gray-400 bg-white border-b border-gray-100'}
        header={
          <div className="flex items-center">
            <FiMapPin className="mr-3 w-6 h-6" />
            Livraison
            {(event.pickup || event.address) &&
              <FiCheck className="ml-2 w-6 h-6" />
            }
          </div>
        }>
        {premium && !event.address &&
          <div className="flex flex-col h-full text-center leading-tight p-1">
            <div className="font-thin text-base text-gray-500">Vous pouvez modifier les détails logistiques jusqu'à 10 jours avant votre événement</div>
            <Button variant="secondary-white" className="mt-3 mx-auto" onClick={() => setOpen(true)}>Renseigner mon adresse</Button>
          </div>
        }
        {premium && event.address &&
          <div className="flex flex-col h-full text-center leading-tight p-1">
            <div className="font-thin text-base text-gray-500">
              {event.address.address1}
              <br/>
              {event.address.zip}&nbsp;{event.address.city}&nbsp;{event.address.country}
            </div>
            <Button variant="secondary" className="mt-3 mx-auto bg-orange hover:bg-orange-dark" onClick={() => setOpen(true)}>Modifier mon adresse</Button>
          </div>
        }
        {!premium && !event.pickup &&
          <div className="flex flex-col h-full text-center leading-tight p-1">
            <div className="font-thin text-base text-gray-500">Vous pouvez modifier les détails logistiques jusqu'à 10 jours avant votre événement</div>
            <Button variant="secondary-white" className="mt-3 mx-auto" onClick={() => setOpen(true)}>Choisir mon point relais</Button>
          </div>
        }
        {!premium && event.pickup &&
          <div className="flex flex-col h-full text-center leading-tight p-1">
            <div className="font-thin text-base text-gray-500">
              <strong>{event.pickup.LgAdr1}</strong>
              <br/>
              {event.pickup.LgAdr3.toLowerCase()}
              <br/>
              {event.pickup.CP} {event.pickup.Ville}
            </div>
            <Button variant="secondary" className="mt-3 mx-auto bg-orange hover:bg-orange-dark" onClick={() => setOpen(true)}>Modifier mon point relais</Button>
          </div>
        }
      </Card>
      {open && premium &&
        <AdressModal event={event} confirmAddress={setAddress} close={() => setOpen(false)} />
      }
      {open && !premium &&
        <PickupModal event={event} setPickup={setPickup} close={() => setOpen(false)} />
      }
    </>
  )
  
}
