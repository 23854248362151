import React, { useEffect, useState } from 'react'
import { FiCheck, FiX } from 'react-icons/fi'
import Button from './Button'
import Popup from './Popup'

export default function Confirm({ handler }) {
  const [pick, setPick] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const trigger = title => {
    setTitle(title)
    return new Promise(resolve => setPick(() => resolve.bind(this)))
  }
  useEffect(() => {
    handler(() => trigger.bind(this))
  }, [])
  const cancel = () => {
    pick(Promise.reject())
    setPick(null)
  }
  const check = () => {
    pick()
    setPick(null)
  }
  if (!pick) return null
  return (
    <Popup title={title} close={cancel}>
      <div className="flex">
        <Button Icon={FiX} onClick={cancel} className="bg-gray-500 m-4">Annuler</Button>
        <Button Icon={FiCheck} onClick={check} className="m-4">Confirmer</Button>
      </div>
    </Popup>
  )
}
