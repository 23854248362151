import React, { useState } from 'react'
import Cookies from 'js-cookie'
import Popup from './UI/Popup'
import Info from './UI/Info'
import Button from './UI/Button'

export default function FranceWarning() {
  const [show, setShow] = useState(!Cookies.get('sb-fr-pro'))
  if (!show) return null
  console.log(Cookies.get('sb-fr-pro'))
  return (
    <Popup small>
      <Info className="text-base mb-4">
        La livraison en France est uniquement ouverte aux professionnels, pour les particuliers, nous vous invitons à vous rapprocher de notre partenaire <a className="underline" href="https://cheerz.com" target="_blank">Cheerz</a>.
      </Info>
      <Button onClick={() => { setShow(false); Cookies.set('sb-fr-pro', true) }}>
        Je suis un professionnel
      </Button>
    </Popup>
  )
}
