import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { getStocks } from '../api'
import Label from './UI/Label'
import Button from './UI/Button'
import { language } from '../utils'
moment.locale('en')

export default function DatePicker({ handler, allowLabel = false }) {
  const [pick, setPick] = useState(undefined)
  const [date, setDate] = useState(undefined)
  const [label, setLabel] = useState(undefined)
  const [stock, setStock] = useState(undefined)
  const trigger = ({ date: defaultDate, label: defaultLabel}) => {
    setDate(defaultDate && moment(defaultDate))
    setLabel(defaultLabel)
    return new Promise(resolve => setPick(() => resolve.bind(this)))
  }
  useEffect(() => {
    getStocks().then(s => setStock(s.map(s => ({ ...s, from: moment(s.from), to: moment(s.to) }))))
    handler(() => trigger.bind(this))
  }, [])
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: `
        .SingleDatePicker_picker { position: relative; top: 0 !important; left: 0; width: 100%; border-radius: 0.4rem; overflow: hidden; box }
        .SingleDatePickerInput { background-color: transparent; min-height: 405px }
        .DateInput_fang { display: none }
        .DateInput { visibility: hidden; height: 0; }
        .DayPicker__withBorder { box-shadow: none; margin: 0 auto; }
        .CalendarDay__selected, .CalendarDay__selected:hover { background: #4da1a9; border-color: #4da1a9; }
        .DateInput_input{ border-bottom: none !important; text-align: center; margin-top: 26px; }
      ` }} />
      {stock && pick &&
        <div className="fixed flex flex-col justify-center items-center h-screen w-screen top-0 left-0 z-40">
          <div className="absolute h-screen w-screen top-0 left-0 bg-black opacity-75" />
          <div className="mx-auto -mb-5" style={{ width: 703 }}>
            {allowLabel && 
              <Label className="lg:pl-0" title={<div className="text-white font-bold">Label</div>}>
                <input className="form-input w-full" type="text" value={label} onChange={e => setLabel(e.target.value)} />
              </Label>
            }
            <div className="relative text-white font-bold mt-4">Date</div>
          </div>
          <SingleDatePicker
            date={date}
            onFocusChange={e => null}
            numberOfMonths={2}
            onDateChange={d => { setDate(d) }}
            firstDayOfWeek={1}
            isOutsideRange={day =>
              day.isSameOrBefore(moment().add(5, 'days')) ||
              (stock.find(s => day.set('hours', 12).isBetween(s.from, s.to.clone().add(1, 'day'))) || { left: 10 }).remaining <= 0
            }
            initialVisibleMonth={() => date || moment().add(2, 'months')}
            daySize={45}
            placeholder=""
            hideKeyboardShortcutsPanel
            block
            focused
            noBorder />
          <div className="flex">
            <Button className="m-4 bg-gray-300" onClick={() => { pick && pick(false); setPick(null); setDate(null); setLabel(null) }}>Annuler</Button>
            <Button className="m-4" onClick={() => { pick && pick({ date: date.format(), label }); setPick(null); setDate(null); setLabel(null) }}>Valider</Button>
          </div>
        </div>
      }
    </>
  )
}